<template>
  <div class="module-three list-scroll" v-infinite-scroll="loadMore">
    <template v-if="list.length > 0">
      <div class="list-item" v-for="l in list" :key="l.id">
        <div class="list-item-left">
          <img :src="baseUrl + (l.img && l.img.file_path)" alt="" />
        </div>
        <div class="list-item-middle">
          <div class="item-name scroll-hidden">{{ l.name || "无" }}</div>
          <div class="item-mes list-scroll">
            {{ l.mes }}
          </div>
        </div>
        <div class="list-item-right">
          <span>{{ l.click_num }}次点击</span>
          <span @click="toDetail(l)">学习课程</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="empty-box">
        <img src="@/assets/images/empty.jpg" alt="" />
        <p>暂无数据</p>
      </div>
    </template>
  </div>
</template>

<script>
import { getModuleBaseList } from "@/api/base.js";
export default {
  name: "ModuleThree",
  data() {
    return {
      loadingList: false,
      searchObj: {
        page: 1,
        pageSize: 20,
        moduleCode: "",
        typeId: 0,
      },
      list: [],
      total: 0,
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        // console.log("val", val);
        const { query } = val;
        this.searchObj.moduleCode = query.module_code;
        this.searchObj.typeId = query.id;
      },
    },
    "searchObj.typeId"() {
      this.getModuleBaseList();
    },
  },
  created() {
    // console.log("created", this.searchObj);
    this.getModuleBaseList();
  },
  methods: {
    getModuleBaseList(loadMore = false) {
      if (!loadMore) {
        this.searchObj.page = 1;
        this.list = [];
      }
      this.loadingList = true;
      let params = {
        pageindex: this.searchObj.page,
        pagesize: this.searchObj.pageSize,
        module_code: this.searchObj.moduleCode,
        type_id: this.searchObj.typeId,
      };
      getModuleBaseList(params)
        .then((res) => {
          // console.log(res);
          if (res.code === 200) {
            const { data } = res;
            this.list = this.list.concat(data.data);
            this.total = data.dataCount;
          }
        })
        .finally(() => {
          this.loadingList = false;
        });
    },
    loadMore() {
      if (this.list.length < this.searchObj.page * this.searchObj.pageSize) {
        return;
      }
      this.searchObj.page += 1;
      this.getModuleBaseList(true);
    },
    toDetail(item) {
      let token = window.localStorage.getItem("wildanimals_token");
      if (!token) {
        this.$message.warning("登录后才能查看，快去登录吧~");
        return;
      }

      this.$emit("clickItem", item.name);

      this.$router.push({
        name: "DetailTwo",
        query: {
          module_code: this.searchObj.moduleCode,
          id: this.searchObj.typeId,
          cid: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .module-three {
    .list-item {
      margin-top: 20px;

      &:last-child {
        margin-bottom: 20px;
      }

      .list-item-left {
        height: 200px;
      }
      .list-item-middle {
        .item-name {
          font-size: 18px;
        }
        .item-mes {
        }
      }
      .list-item-right {
        font-size: 14px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .module-three {
    .list-item {
      margin-top: 10px;

      &:last-child {
        margin-bottom: 10px;
      }

      .list-item-left {
        height: 144px;
      }
      .list-item-middle {
        .item-name {
          font-size: 14px;
        }
        .item-mes {
        }
      }
      .list-item-right {
        font-size: 12px;
      }
    }
  }
}
.module-three {
  overflow-y: auto;
  .list-item {
    width: 48%;
    float: left;
    display: flex;
    flex-direction: column;
    margin-right: 4%;
    padding: 2%;
    border: 1px solid rgba(0, 0, 0, 0.21);

    &:nth-child(2n) {
      margin-right: 0;
    }

    .list-item-left {
      background: #fff;
      border: 1px solid #e4e4e4;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .list-item-middle {
      margin-top: 4%;
      .item-name {
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #525252;
        overflow-x: scroll;
        white-space: nowrap;
      }
      .item-mes {
        margin-top: 4%;
        height: 54px;
        font-size: 12px;
        font-family: SimSun;
        font-weight: 400;
        color: #525252;
        overflow: auto;
        line-height: 1.5;
        word-break: break-all;
      }
    }

    .list-item-right {
      margin-top: 4%;
      height: 30px;
      line-height: 30px;

      font-family: SimSun;
      font-weight: 400;
      color: #ffffff;
      display: flex;

      span {
        width: 50%;
        text-align: center;
        background: #f2b624;
        color: #525252;

        &:first-child {
          background: #007cf9;
          color: #ffffff;
        }
        &:last-child {
          cursor: pointer;
        }
      }
    }
  }
}
</style>